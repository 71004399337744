export default function PlusCircleSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M18 3C9.72909 3 3 9.72909 3 18C3 26.2709 9.72909 33 18 33C26.2709 33 33 26.2709 33 18C33 9.72909 26.2709 3 18 3ZM23.7692 19.1538H19.1538V23.7692C19.1538 24.0752 19.0323 24.3687 18.8159 24.5851C18.5995 24.8015 18.306 24.9231 18 24.9231C17.694 24.9231 17.4005 24.8015 17.1841 24.5851C16.9677 24.3687 16.8462 24.0752 16.8462 23.7692V19.1538H12.2308C11.9248 19.1538 11.6313 19.0323 11.4149 18.8159C11.1985 18.5995 11.0769 18.306 11.0769 18C11.0769 17.694 11.1985 17.4005 11.4149 17.1841C11.6313 16.9677 11.9248 16.8462 12.2308 16.8462H16.8462V12.2308C16.8462 11.9248 16.9677 11.6313 17.1841 11.4149C17.4005 11.1985 17.694 11.0769 18 11.0769C18.306 11.0769 18.5995 11.1985 18.8159 11.4149C19.0323 11.6313 19.1538 11.9248 19.1538 12.2308V16.8462H23.7692C24.0752 16.8462 24.3687 16.9677 24.5851 17.1841C24.8015 17.4005 24.9231 17.694 24.9231 18C24.9231 18.306 24.8015 18.5995 24.5851 18.8159C24.3687 19.0323 24.0752 19.1538 23.7692 19.1538Z"
        fill="#111318"
      />
    </svg>
  );
}
