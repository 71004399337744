import React from "react";
import { useTranslation } from "react-i18next";

export default function FingerTip() {
  const { t } = useTranslation();
  return (
    <div className="lp-tip" id="function">
      <div className="container">
        <div className="lp-tip__title">{t("page.landing.fingerTip.title")}</div>
        <div className="lp-tip__desc">{t("page.landing.fingerTip.desc")}</div>
        {/* <div className="lp-tip__desc">
          No more bulky wallet or tangled keychains, the The Ring comes to your
          rescue. Elevator and apartment keys? Check. Credit card? Check.
          <br />
          All daily essentials are magically integrated in one ring.
          <br />
          <p className="max-w-[460px] mx-auto">
            It declutters your mind of troublesome and spares you for more
            enjoyable moments of life.
          </p>
        </div> */}
      </div>
    </div>
  );
}
