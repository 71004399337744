import { Result, Spin } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import BoxComponentPng from "@app-assets/images/box-components.png";
import ProductImage1 from "@app-assets/images/products/Background1.png";
import ProductImage2 from "@app-assets/images/products/Background2.png";
import ProductImage3 from "@app-assets/images/products/Background3.png";
import ProductImage4 from "@app-assets/images/products/Background4.png";
import ProductImage from "@app-assets/images/products/Background.png";
// import RingSizeChart from "@app-assets/images/ring-size-chart-lg.png";
import StyledButton from "@app-components/button/StyledButton";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import { SEO } from "@app-components/header/SEO";
import MTInputNumber from "@app-components/input/MTInputNumber";
import MTCarousel from "@app-components/media/MTCarousel";
import MTVideo from "@app-components/media/MTVideo";
import DetailCard, {
  ProductDetailType,
} from "@app-components/product/DetailCard";
import MeasureRingGuide from "@app-components/product/MeasureRingGuide";
import MTSelect from "@app-components/select/MTSelect";
import { OutlineIcon } from "@app-components/svg/icon";
import UserGuide from "@app-components/userGuide/UserGuide";
import { ringSizeOptions } from "@app-constants/product";
import { CartData } from "@app-interface/cart";
import { IProduct } from "@app-interface/product";
import { productDetailPayloadAtom } from "@app-jotai/shopping";
import { useProductWriteOnly } from "@app-jotai/shopping/setters";
import { Mixpanel, PREORDER_ITEM, PREORDER_PAGE } from "@app-mixpanel/index";
import { BOX_MATERIAL } from "@app-mockData/productDetail";
import { generateCartId } from "@app-utils/cart";
// import { scrollToElementId } from "@app-utils/jQuery";
import { toMoney } from "@app-utils/number";
import { PATHS, getRoutes } from "@app-utils/routes";

export default function ProductDetail() {
  const { t } = useTranslation();
  const { onGetProduct, onUpdateCartList } = useProductWriteOnly();
  const [addingToCart, setAddingToCart] = useState(false);
  const [quantity, setQuantity] = useState<number | null>(1);
  const [selectedColor] = useState<string>("black");
  const [selectedRingSize, setSelectedRingSize] = useState<number>(7);
  const {
    data: productDetail,
    loading,
    error,
  } = useAtomValue(productDetailPayloadAtom);

  const params = useParams();

  const navigate = useNavigate();
  const routes = getRoutes(t);

  useEffect(() => {
    Mixpanel.track(PREORDER_PAGE);
    if (params.id) {
      onGetProduct(+params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePurchase = () => {
    if (addingToCart) return;
    setAddingToCart(true);
    const newCartItem: CartData = {
      quantity: quantity || 1,
      size: selectedRingSize,
      color: selectedColor,
      product: productDetail as IProduct,
      selected: true,
    };
    newCartItem.cartId = generateCartId(
      newCartItem.product.id,
      selectedRingSize,
      selectedColor
    );
    onUpdateCartList(newCartItem);

    setAddingToCart(false);

    Mixpanel.track(PREORDER_ITEM, {
      size: selectedRingSize,
      quantity,
    });

    navigate(PATHS.checkout);
  };

  const handleChangeRingSize = (value: any) => {
    setSelectedRingSize(value);
  };

  const handleChangeQuantity = (value: number | null) => {
    setQuantity(value);
  };

  if (loading) {
    return (
      <div className="container flex items-center justify-center">
        <Spin />
      </div>
    );
  } else if (error) {
    return (
      <div className="container flex items-center justify-center">
        <SEO title={t("page.product.productNotFound")} />
        <Result
          status="500"
          title={t("page.product.productNotFound")}
          subTitle={t("page.product.notFoundTitle")}
          extra={
            <StyledButton className="primary">
              {t("page.product.clickToReload")}
            </StyledButton>
          }
        />
      </div>
    );
  }

  return (
    <div className="product">
      <SEO title={productDetail?.name} />
      <div className="breadcrumb">
        <div className="container">
          <MTBreadcrumb
            items={[
              routes.home,
              { title: t("title.productDetails"), path: "" },
            ]}
          />
        </div>
      </div>
      <div className="container product-intro flex-row">
        <div className="product-intro__left">
          <div className="product-intro__image">
            <MTCarousel
              autoplay
              images={[
                ProductImage,
                ProductImage1,
                ProductImage2,
                ProductImage3,
                ProductImage4,
              ]}
            />
          </div>
        </div>
        <div className="product-intro__right">
          <div className="product-intro__content">
            <div className="product-intro__title mt-display-md">
              {productDetail?.name}
            </div>
            <div className="product-intro__price mt-display-sm">
              {toMoney(productDetail?.price || 0)} VNĐ
            </div>
            <div className="product-intro__desc mt-text-body-xl">
              {t("page.product.description")}
              <div>{t("page.product.madeIn")}</div>
              <div>
                {t("page.product.productOf")} {t("contact.companyName")}
              </div>
              <MeasureRingGuide onSelectRingSize={setSelectedRingSize} />
            </div>

            <div className="product-intro__form">
              {/* <MTSelect
                className="product-intro__form-select"
                label={t("page.product.color")}
                options={colorOptions.map((color: OptionData) => ({
                  ...color,
                  label: t(color.label.toString()),
                }))}
                value={selectedColor}
                onChange={handleChangeColor}
              /> */}
              <MTSelect
                className="product-intro__form-select"
                label={t("page.product.size")}
                // label={
                //   <>
                //     <div>{t("page.product.size")}</div>
                //     <div
                //       className="sub-label"
                //       onClick={handleScrollToSizeChart}
                //     >
                //       {t("page.product.seeSizeChart")}
                //     </div>
                //   </>
                // }
                options={ringSizeOptions}
                value={selectedRingSize}
                onChange={handleChangeRingSize}
              />
              <MTInputNumber
                className="product-intro__form-number"
                max={100}
                min={1}
                placeholder={t("page.product.quantity")}
                label={t("page.product.quantity")}
                value={quantity}
                onChange={handleChangeQuantity}
              />
            </div>
            <div className="product-intro__pre-order">
              <div className="desc">
                <div>
                  <OutlineIcon.Package />
                </div>
                <div className="mt-text-body-xl">
                  {t("page.product.preOrderContent")}
                </div>
              </div>

              <a className="order-link" href={routes.preOrder.path}>
                {routes.preOrder.title}
              </a>
            </div>
            <StyledButton
              className="primary"
              loading={addingToCart}
              onClick={handlePurchase}
            >
              {
                //  t("page.product.preOrderComingSoon")
                t("page.product.preOrder")
              }
            </StyledButton>
          </div>
        </div>
      </div>
      <div className="product-detail">
        <div className="container">
          <div className="product-detail__section">
            <div className="product-detail__title">
              {t("page.product.productDetail")}
            </div>
            <div className="product-detail__cols">
              {Object.values(ProductDetailType).map((key: any) => (
                <DetailCard type={key} key={key} />
              ))}
            </div>
          </div>
          <div className="product-detail__section">
            <div className="product-detail__video">
              <div className="product-detail__title">
                {t("page.product.box")}
              </div>
              <div className="product-detail__video-items">
                {BOX_MATERIAL.map((item) => (
                  <div
                    key={item.placeholder}
                    className="product-detail__video-item-container"
                  >
                    <div className="product-detail__video-item-thumbnail">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="product-detail__video-item-description">
                      {t(item.placeholder)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="product-detail__video-wrapper">
                <MTVideo
                  id="product-detail-video"
                  videoUrl="https://video.wixstatic.com/video/9a71f9_e9e5c7ec4bc240a38bb0ba5acfeafd49/1080p/mp4/file.mp4"
                  thumbnail={BoxComponentPng}
                />
              </div>
            </div>
          </div>

          <div className="product-detail__section">
            <div className="product-detail__title whitespace-break-spaces">
              {t("page.product.usingTheRing")}
            </div>
            <UserGuide />
          </div>

          {/* <div className="product-detail__section">
            {productDetail?.detail?.summary && (
              <div className="product-detail__summary">
                <div className="product-detail__item-title">
                  {t(productDetail.detail?.summary?.title)}
                </div>
                <div className="product-detail__item-content">
                  {t(productDetail.detail?.summary?.content)}
                </div>
              </div>
            )}
          </div> */}

          {/* <div className="product-detail__section">
            <div
              className="product-detail__size-chart"
              id="product-detail-size-chart"
            >
              <div className="product-detail__title">
                {t("page.product.sizeChart")}
              </div>
              <div>
                <Image src={RingSizeChart} alt="ring size chart" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
