import { useTranslation } from "react-i18next";

import MemberCarousel from "./MemberCarousel";

const MEMBERS = [
  {
    name: "Paul Emile Destouches",
    role: "page.aboutUs.section4.role.ceo",
    image: require("@app-assets/images/people/polo.jpg"),
    desc: "page.aboutUs.section4.desc.polo",
  },
  {
    name: "Cao Trần",
    role: "page.aboutUs.section4.role.headOfProduct",
    image: require("@app-assets/images/people/cika.jpg"),
    desc: "page.aboutUs.section4.desc.cika",
  },
  {
    name: "Yến Thiệu",
    role: "page.aboutUs.section4.role.headOfMarketing",
    image: require("@app-assets/images/people/yen.jpg"),
    desc: "page.aboutUs.section4.desc.yen",
  },
  {
    name: "Guillaume Copp",
    role: "page.aboutUs.section4.role.headOfPartnership",
    image: require("@app-assets/images/people/copp.jpg"),
    desc: "page.aboutUs.section4.desc.copp",
  },
  {
    name: "Nam Nguyễn",
    role: "page.aboutUs.section4.role.headOfFinance",
    image: require("@app-assets/images/people/nam.jpg"),
    desc: "page.aboutUs.section4.desc.name",
  },
  {
    name: "Linh",
    role: "page.pns.team.headOfPnsDev.title",
    image: require("@app-assets/images/people/linh.jpg"),
    desc: "page.pns.team.headOfPnsDev.desc",
  },
  {
    name: "Lộc Triệu",
    role: "page.pns.team.strategyConsultant.title",
    image: require("@app-assets/images/people/trieu.jpg"),
    desc: "page.pns.team.strategyConsultant.decs",
  },
];
export default function People() {
  const { t } = useTranslation();

  return (
    <div className="container pt-[40px] pb-[70px]">
      <div className="text-[38px] font-semibold leadnig-[1.2] text-center pb-[30px] md:pb-[56px]">
        {t("page.aboutUs.section4.title")}
      </div>
      <div className="member-carousel-container">
        <MemberCarousel members={MEMBERS} />
      </div>
    </div>
  );
}
