import { Modal } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddressInput from "@app-components/address/AddressInput";
import StyledButton from "@app-components/button/StyledButton";
import MTSelect from "@app-components/select/MTSelect";
import { CreateAddressReq } from "@app-interface/address";
import { isLoggedAtom } from "@app-jotai/auth";
import { addressesListPayloadAtom, useUserWriteOnly } from "@app-jotai/user";
import addressServices from "@app-services/addressServices";
import useService from "@app-services/shared/use_service";

const useCreateAddress = () => useService(addressServices.createAddress);
const useEditAddress = () => useService(addressServices.editAddress);

export default function NewUserAddress({
  onSelectAddressId,
}: {
  onSelectAddressId: (id: number) => void;
}) {
  const { t } = useTranslation();
  const { getAddressesList } = useUserWriteOnly();

  const isLogged = useAtomValue(isLoggedAtom);

  const [openModal, setOpenModal] = useState(false);

  const [addressId, setAddressId] = useState<number>();

  const [isEdit, setIsEdit] = useState(false);

  const [addressReq, setAddressReq] = useState<CreateAddressReq>({
    primary: false,
    value: "",
  });

  const { data: addressesList } = useAtomValue(addressesListPayloadAtom);

  const [createAddressPayload, onCreateAddress, clearCreateAddressState] =
    useCreateAddress();

  const [editAddressPayload, onEditAddress, clearEditAddressState] =
    useEditAddress();

  useEffect(() => {
    if (isLogged) {
      getAddressesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (addressesList) {
      const id = addressesList?.models?.find((i) => i.primary)?.id;
      if (id) setAddressId(id);
    }
  }, [addressesList]);

  useEffect(() => {
    onSelectAddressId?.(addressId as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  useEffect(() => {
    if (isEdit) {
      const addr = addressesList?.models?.find((item) => item.id === addressId);
      setAddressReq({
        value: addr?.value || "",
        primary: addr?.primary || false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    if (createAddressPayload?.success || editAddressPayload?.success) {
      if (!isEdit) {
        setAddressId(createAddressPayload?.data?.id || addressId);
      }
      getAddressesList();
      handleCloseModal();
      clearCreateAddressState();
      clearEditAddressState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddressPayload, editAddressPayload]);

  const handleCreateAddress = () => {
    if (isEdit) {
      onEditAddress(addressId as number, addressReq);
      return;
    }
    onCreateAddress(addressReq);
  };

  const handleCloseModal = () => {
    clearCreateAddressState();
    setIsEdit(false);
    setOpenModal(false);
    setAddressReq({ value: "", primary: false });
  };

  return (
    <>
      <div className="mt-[16px]">
        <MTSelect
          className="w-full"
          placeholder={t("input.address.placeholder")}
          onChange={(e) => setAddressId(e)}
          options={addressesList?.models.map((item) => ({
            value: item.id,
            label: item.value,
          }))}
          value={addressId}
          size="sm"
        />
        <div className="text-center pt-2">
          {!!addressId && (
            <span
              className="underline cursor-pointer"
              onClick={() => {
                const addr = addressesList?.models?.find(
                  (item) => item.id === addressId
                );
                setAddressReq({
                  value: addr?.value || "",
                  primary: addr?.primary || false,
                });
                setIsEdit(true);
                setOpenModal(true);
              }}
            >
              {t("label.editAddress")}
            </span>
          )}
          <span> or </span>
          <span
            className="underline cursor-pointer"
            onClick={() => setOpenModal(true)}
          >
            {t("label.createNewAddress")}
          </span>
        </div>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onOk={handleCreateAddress}
          onCancel={handleCloseModal}
          width={768}
          footer={
            <>
              <StyledButton onClick={handleCloseModal} className="outline">
                {t("button.cancel")}
              </StyledButton>
              <StyledButton
                disabled={!addressReq.value}
                onClick={handleCreateAddress}
                className="primary"
              >
                {t("button.ok")}
              </StyledButton>
            </>
          }
        >
          <div className="pt-4">
            <AddressInput
              initAddress={isEdit ? addressReq : undefined}
              onUpdateData={(data) => setAddressReq(data)}
            />
          </div>
          {/* <div className="pt-4">
          <div>
            <MTInput
              label={t("input.address.label")}
              value={addressReq.value}
              onChangeText={(value) =>
                setAddressReq({
                  ...addressReq,
                  value: value,
                })
              }
            />
          </div>
          <div className="pt-4">
            <Checkbox
              checked={addressReq.primary}
              onChange={(e) =>
                setAddressReq({
                  ...addressReq,
                  primary: e.target.checked,
                })
              }
            >
              {t("label.defaultAddress")}
            </Checkbox>
          </div>
        </div> */}
        </Modal>
      )}
    </>
  );
}
