import React from "react";

export default function BuildingOutline() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_977_5147)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8191 15.874C14.2757 15.5086 14.9246 15.5086 15.3812 15.874L25.3979 23.8907C25.6943 24.1279 25.8668 24.487 25.8668 24.8666V36.6666C25.8668 37.357 25.3072 37.9166 24.6168 37.9166H18.1168C17.4265 37.9166 16.8668 37.357 16.8668 36.6666V29.8H12.3335V36.6666C12.3335 37.357 11.7739 37.9166 11.0835 37.9166H4.5835C3.89314 37.9166 3.3335 37.357 3.3335 36.6666V24.8666C3.3335 24.487 3.50603 24.1279 3.80243 23.8907L13.8191 15.874ZM5.8335 25.4673V35.4166H9.8335V28.55C9.8335 27.8596 10.3931 27.3 11.0835 27.3H18.1168C18.8072 27.3 19.3668 27.8596 19.3668 28.55V35.4166H23.3668V25.4673L14.6002 18.451L5.8335 25.4673Z"
          fill="#111318"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2666 3.33331C13.2666 2.64296 13.8262 2.08331 14.5166 2.08331H34.6666C35.357 2.08331 35.9166 2.64296 35.9166 3.33331V36.6666C35.9166 37.357 35.357 37.9166 34.6666 37.9166H29.1333C28.4429 37.9166 27.8833 37.357 27.8833 36.6666C27.8833 35.9763 28.4429 35.4166 29.1333 35.4166H33.4166V4.58331H15.7666V12.2166C15.7666 12.907 15.207 13.4666 14.5166 13.4666C13.8262 13.4666 13.2666 12.907 13.2666 12.2166V3.33331Z"
          fill="#111318"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3003 8.1333C18.3003 7.44294 18.8599 6.8833 19.5503 6.8833H29.6503C30.3407 6.8833 30.9003 7.44294 30.9003 8.1333C30.9003 8.82366 30.3407 9.3833 29.6503 9.3833H19.5503C18.8599 9.3833 18.3003 8.82366 18.3003 8.1333Z"
          fill="#111318"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3003 12.7167C18.3003 12.0263 18.8599 11.4667 19.5503 11.4667H29.6503C30.3407 11.4667 30.9003 12.0263 30.9003 12.7167C30.9003 13.407 30.3407 13.9667 29.6503 13.9667H19.5503C18.8599 13.9667 18.3003 13.407 18.3003 12.7167Z"
          fill="#111318"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0835 17.3C22.0835 16.6096 22.6431 16.05 23.3335 16.05H29.6502C30.3405 16.05 30.9002 16.6096 30.9002 17.3C30.9002 17.9903 30.3405 18.55 29.6502 18.55H23.3335C22.6431 18.55 22.0835 17.9903 22.0835 17.3Z"
          fill="#111318"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7168 21.8833C26.7168 21.1929 27.2764 20.6333 27.9668 20.6333H29.6501C30.3405 20.6333 30.9001 21.1929 30.9001 21.8833C30.9001 22.5737 30.3405 23.1333 29.6501 23.1333H27.9668C27.2764 23.1333 26.7168 22.5737 26.7168 21.8833Z"
          fill="#111318"
        />
      </g>
      <defs>
        <clipPath id="clip0_977_5147">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
