import { Radio, Space } from "antd";
import { useAtomValue, useSetAtom } from "jotai";
import Lottie from "lottie-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import validator from "validator";

import lottieData from "@app-assets/lotties/purchase-success.json";
import StyledButton from "@app-components/button/StyledButton";
import NewAnonymousAddress from "@app-components/checkout/NewAnonymousAddress";
import NewUserAddress from "@app-components/checkout/NewUserAddress";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import MTInput from "@app-components/input/MTInput";
import MTTextArea from "@app-components/input/MTTextArea";
import MeasureRingGuide from "@app-components/product/MeasureRingGuide";
import NewCartItem from "@app-components/shopping/NewCartItem";
import EmptyRingSvg from "@app-components/svg/EmptyRingSvg";
import { SolidIcon } from "@app-components/svg/icon";
import { LocalStorageKeys } from "@app-constants/common";
import { OrderStatus, PaymentMethod } from "@app-constants/order";
import { colorOptions } from "@app-constants/product";
import useScrollToTop from "@app-hook/useScrollToTop";
import { CartData } from "@app-interface/cart";
import { CreateOrderReq, IOrder } from "@app-interface/order";
import { IProduct } from "@app-interface/product";
import { isLoggedAtom } from "@app-jotai/auth";
import { cartListAtom } from "@app-jotai/shopping";
import { formattedCartListAtom } from "@app-jotai/shopping/getters";
import { useProductWriteOnly } from "@app-jotai/shopping/setters";
import { profileAtom, useUserWriteOnly } from "@app-jotai/user";
import {
  CHECKOUT_PAGE,
  GUEST_ORDER_SUCCESS,
  MEMBER_ORDER_SUCCESS,
  Mixpanel,
} from "@app-mixpanel/index";
import orderServices from "@app-services/orderServices";
import productServices from "@app-services/productServices";
import useService from "@app-services/shared/use_service";
import { generateCartId, omitByFalsyValue } from "@app-utils/cart";
import { formatPhoneNumber, toMoney } from "@app-utils/number";
import { PATHS, getRoutes } from "@app-utils/routes";

const useCreateOrder = () => useService(orderServices.createOrder);
const useCreateAnonymousOrder = () =>
  useService(orderServices.createOrderAnonymous);
const useGetProductDetail = () => useService(productServices.getDetail);

export default function NewCheckoutPage() {
  useScrollToTop();
  const { t } = useTranslation();
  const routes = getRoutes(t);
  const [searchParams] = useSearchParams();
  const profile = useAtomValue(profileAtom);

  const { onClearPurchasedCart } = useProductWriteOnly();

  const isLogged = useAtomValue(isLoggedAtom);
  const setCheckoutData = useSetAtom(cartListAtom);
  const cartList = useAtomValue(formattedCartListAtom);
  const [productPayload, getProductDetail] = useGetProductDetail();

  const { getAddressesList } = useUserWriteOnly();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [addressId, setAddressId] = useState<number>();
  const [tempPayload, setTempPayload] = useState<any>({});
  const [nextAction, setNextAction] = useState(false);

  const [orderStatus, setOrderStatus] = useState(
    searchParams.get("status") || ""
  );

  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ONEPAY);

  const includeNewItem = useMemo(() => {
    return cartList.some((item) => !item.size);
  }, [cartList]);

  const validPhone = validator.isMobilePhone(phone, "vi-VN");
  const validEmail = validator.isEmail(email);

  const paymentOptions = [
    {
      value: PaymentMethod.ONEPAY,
      label: t("content.payment.onlinePayment"),
      icon: <SolidIcon.Card />,
    },
    // {
    //   value: PaymentMethod.COD,
    //   label: t("content.payment.cod"),
    //   icon: <SolidIcon.Money />,
    // },
  ];

  const subTotal = useMemo(
    () =>
      cartList?.reduce(
        (result, item) => {
          if (item.size) {
            result.total += item.quantity * item.product.price;
            result.count += item.quantity;
          }

          return result;
        },
        { total: 0, count: 0 }
      ),
    [cartList]
  );

  const { onUpdateCartList, onDeleteCartItem } = useProductWriteOnly();
  const [createOrderPayload, onCreateOrder] = useCreateOrder();

  const [createAnonymousOrderPayload, onCreateAnonymousOrder] =
    useCreateAnonymousOrder();

  const handleUpdateCartItem = (currentId: string, data: CartData) => {
    onUpdateCartList(data, currentId);
  };

  const handleDeleteCartItem = (currentId: string) => {
    onDeleteCartItem(currentId);
  };

  const handleOrder = () => {
    const payload = omitByFalsyValue({
      paymentMethod,
      addressId,
      email,
      name,
      items: cartList?.map((i) => ({
        productId: i.product.id,
        quantity: i.quantity,
        size: i.size,
      })),
    });

    setTempPayload({ ...payload, checkoutData: cartList });

    if (isLogged) {
      onCreateOrder(payload as CreateOrderReq);
      return;
    }

    onCreateAnonymousOrder({
      ...payload,
      phone: formatPhoneNumber(phone),
    } as CreateOrderReq);
  };

  const handlePayment = (data: any) => {
    setOrderStatus(data.status);
    if (data?.paymentUrl) {
      setNextAction(true);
      window.location.href = data.paymentUrl;
    }
  };

  const getNewItem = () => ({
    quantity: 1,
    size: 0,
    color: colorOptions[0].value as string,
    product: productPayload.data as IProduct,
    selected: true,
    cartId: generateCartId(productPayload.data.id, 0, "black"),
  });

  const handleOnAddRing = () => {
    const newCartItem: CartData = getNewItem();
    onUpdateCartList(newCartItem);
  };

  const handleStoreAnonymousOrder = (order: IOrder) => {
    let anonymousOrders = [];
    try {
      anonymousOrders =
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.ANONYMOUS_ORDERS) || "[]"
        ) || [];
    } catch (error) {
      anonymousOrders = [];
    }
    anonymousOrders.push({
      ...order,
      orderItems: tempPayload.checkoutData.map(
        ({
          product,
          quantity,
          size,
        }: {
          product: IProduct;
          quantity: number;
          size: number;
        }) => ({
          product,
          quantity,
          size,
        })
      ),
    });
    setTempPayload({});
    window.localStorage.setItem(
      LocalStorageKeys.ANONYMOUS_ORDERS,
      JSON.stringify(anonymousOrders)
    );
  };
  const handleOnSelectRingSize = (size: number) => {
    const newItm = getNewItem();
    if (newItm && includeNewItem) {
      onUpdateCartList({ ...newItm, size }, newItm.cartId);
    }
  };
  // effect
  useEffect(() => {
    if (isLogged) {
      getAddressesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);
  // effect

  useEffect(() => {
    // if (!cartList?.length && !orderStatus) {
    //   navigate("/");
    // } else {
    // }
    Mixpanel.track(CHECKOUT_PAGE);
    getProductDetail(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) {
      getAddressesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (profile?.email) {
      setEmail(profile.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (createOrderPayload?.success) {
      onClearPurchasedCart();
      setCheckoutData([]);
      handlePayment(createOrderPayload.data);
      Mixpanel.track(MEMBER_ORDER_SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrderPayload?.success]);

  useEffect(() => {
    if (createAnonymousOrderPayload?.success) {
      onClearPurchasedCart();
      handleStoreAnonymousOrder(createAnonymousOrderPayload.data);
      setCheckoutData([]);
      handlePayment(createAnonymousOrderPayload.data);
      Mixpanel.track(GUEST_ORDER_SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAnonymousOrderPayload?.success]);

  if ([OrderStatus.PAID, OrderStatus.PENDING].includes(orderStatus)) {
    return (
      <div className="checkout container flex justify-center items-center flex-col">
        <Lottie className="" animationData={lottieData} loop />
        {nextAction ? (
          <>
            <div className="text-xl font-bold pb-4">
              {t("page.checkout.directToOnlinePayment")}
            </div>
          </>
        ) : (
          <>
            <div className="text-xl font-bold pb-4">
              {t("message.purchaseSucessful")}
            </div>
            <Link to={PATHS.orders}>
              <StyledButton className="primary">
                {t("button.goToOrder")}
              </StyledButton>
            </Link>
          </>
        )}
      </div>
    );
  }

  if (orderStatus === OrderStatus.FAILED) {
    return (
      <div className="checkout container flex justify-center items-center flex-col">
        <div className="text-xl font-bold pb-4">
          {t("message.purchaseFailed")}
        </div>
        <div className="text-xl font-bold pb-4">
          {searchParams.get("paymentMessage")}
        </div>
        <Link to={PATHS.orders}>
          <StyledButton className="primary">
            {t("button.goToOrder")}
          </StyledButton>
        </Link>
      </div>
    );
  }

  return (
    <div className="container checkout-page">
      <div className="checkout-page__inner">
        <MTBreadcrumb items={[routes.home, routes.checkout]} />
        <div className="title">{t("page.checkout.yourCart")}</div>
        <div className="checkout-page__inner-content">
          {/* summary */}
          <div className="card">
            {cartList.length ? (
              <>
                <div className="list">
                  {cartList?.map((data, index) => (
                    <NewCartItem
                      key={data.cartId || index}
                      data={data}
                      onUpdate={(newData) =>
                        handleUpdateCartItem(data?.cartId || "", newData)
                      }
                      onDelete={() => handleDeleteCartItem(data.cartId || "")}
                    />
                  ))}
                </div>
                <div className="add-new-item-container">
                  {includeNewItem && (
                    <div className="w-full flex justify-end">
                      <MeasureRingGuide
                        onSelectRingSize={handleOnSelectRingSize}
                      />
                    </div>
                  )}
                  <StyledButton
                    className="outline mt-button-lg w-[190px]"
                    onClick={handleOnAddRing}
                  >
                    {t("button.addRing")}
                  </StyledButton>
                </div>
              </>
            ) : (
              <div className="empty-item-container">
                <EmptyRingSvg />
                <div>{t("page.checkout.emptyCart")}</div>
                <StyledButton
                  className="primary mt-button-lg w-[190px]"
                  onClick={handleOnAddRing}
                >
                  {t("button.addRing")}
                </StyledButton>
              </div>
            )}

            <div className="checkout-total">
              <div className="checkout-total__item">
                <div className="label">
                  {t("page.checkout.subtotal", { count: subTotal.count })}
                </div>
                <div className="value">{toMoney(subTotal.total)}đ</div>
              </div>
              <div className="checkout-total__item">
                <div className="label">{t("page.checkout.deliveryFee")}</div>
                <div className="value">{t("page.checkout.free")}</div>
              </div>
            </div>
          </div>

          {/* userinfo */}
          <div className="card">
            <div className="card__title">
              {t("content.customerInformation")}
            </div>
            <div className="form-info form-info--customer">
              <div className="form-item form-item--name">
                <MTInput
                  label={t("input.name.label")}
                  floating={false}
                  value={name}
                  onChangeText={(value) => setName(value)}
                />
              </div>
              <div className="form-item form-item--phone">
                <MTInput
                  label={t("input.phoneNumber.label") + "*"}
                  floating={false}
                  value={phone}
                  onChangeText={(value) => setPhone(value)}
                  error={phone && !validPhone ? t("content.invalidPhone") : ""}
                />
              </div>
              <div className="form-item form-item--email">
                <MTInput
                  label={t("input.email.label") + "*"}
                  floating={false}
                  value={email}
                  onChangeText={(value) => setEmail(value)}
                  error={email && !validEmail ? t("content.invalidEmail") : ""}
                />
              </div>
              <div className="form-item form-item--note">
                <MTTextArea
                  label={t("input.note.label")}
                  floating={false}
                  value={note}
                  onChangeText={(value) => setNote(value)}
                />
              </div>
            </div>

            <div className="card__title">
              {t("content.deliveryInformation")}
            </div>
            <div className="form-info">
              {isLogged ? (
                <NewUserAddress onSelectAddressId={(id) => setAddressId(id)} />
              ) : (
                <NewAnonymousAddress
                  onSelectAddressId={(id) => setAddressId(id)}
                />
              )}
            </div>
            <div className="card__title">
              {t("content.chooseYourPaymentMethod")}
            </div>
            <div className="form-info mt-[16px]">
              <Radio.Group
                onChange={(e) => setPaymentMethod(e.target.value)}
                value={paymentMethod}
              >
                {paymentOptions.map(({ value, label, icon }) => (
                  <Radio value={value} key={value}>
                    <Space>
                      {label}
                      {icon}
                    </Space>
                  </Radio>
                ))}
              </Radio.Group>
            </div>

            <div className="checkout-total total">
              <div className="flex justify-between items-center">
                <div className="checkout-total__item">
                  <div className="label">Total:</div>
                  <div className="value">{toMoney(subTotal.total)}đ</div>
                </div>
                <StyledButton
                  className="primary"
                  disabled={
                    !cartList?.length ||
                    !addressId ||
                    !validPhone ||
                    !validEmail ||
                    !includeNewItem
                  }
                  onClick={handleOrder}
                >
                  {t("button.order")}
                </StyledButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
