import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { LocalStorageKeys } from "@app-constants/common";
import { CartData } from "@app-interface/cart";
import { IProduct } from "@app-interface/product";

import { INIT_PAYLOAD } from "../constants";
import { Payload } from "../types";

const listProductsPayloadAtom = atom<Payload<any>>(INIT_PAYLOAD);

const productDetailPayloadAtom = atom<Payload<IProduct>>(INIT_PAYLOAD);

const cartListAtom = atomWithStorage<CartData[]>(LocalStorageKeys.CART, []);

const checkoutProductListAtom = atomWithStorage<CartData[]>(
  LocalStorageKeys.CHECKOUT,
  []
);

export {
  listProductsPayloadAtom,
  productDetailPayloadAtom,
  cartListAtom,
  checkoutProductListAtom,
};
