import { Modal } from "antd";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddressInput from "@app-components/address/AddressInput";
import StyledButton from "@app-components/button/StyledButton";
import MTSelect from "@app-components/select/MTSelect";
import { LocalStorageKeys } from "@app-constants/common";
import { CreateAddressReq, IAddress } from "@app-interface/address";
import addressServices from "@app-services/addressServices";
import useService from "@app-services/shared/use_service";

const useCreateAddress = () =>
  useService(addressServices.createAddressAnonymous);

export default function NewAnonymousAddress({
  onSelectAddressId,
}: {
  onSelectAddressId: (id: number) => void;
}) {
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<IAddress[]>([]);

  const [openModal, setOpenModal] = useState(false);

  const [addressId, setAddressId] = useState<number>();

  const [addressReq, setAddressReq] = useState<CreateAddressReq>({
    primary: false,
    value: "",
  });

  const [createAddressPayload, onCreateAddress, clearCreateAddressState] =
    useCreateAddress();

  useEffect(() => {
    handleGetAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createAddressPayload?.success) {
      setAddressId(createAddressPayload?.data?.id || addressId);
      handleCloseModal();
      // getAddressesList();
      handleStoreNewAddress(createAddressPayload.data);

      clearCreateAddressState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddressPayload]);

  useEffect(() => {
    onSelectAddressId?.(addressId as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  const handleGetAddresses = () => {
    try {
      const addresses =
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.ANONYMOUS_ADDRESSES) ||
            "[]"
        ) || [];
      setAddresses(addresses);
    } catch (error) {
      setAddresses([]);
    }
  };

  const handleCreateAddress = () => {
    onCreateAddress(addressReq);
  };

  const handleCloseModal = () => {
    clearCreateAddressState();
    setOpenModal(false);
    setAddressReq({ value: "", primary: false });
  };

  const handleStoreNewAddress = (address: IAddress) => {
    try {
      const newAddresses = cloneDeep(addresses);
      newAddresses.push(address);
      window.localStorage.setItem(
        LocalStorageKeys.ANONYMOUS_ADDRESSES,
        JSON.stringify(newAddresses)
      );
      setAddresses(newAddresses);
    } catch (error) {}
  };

  return (
    <>
      <div className="mt-[16px]">
        <MTSelect
          className="w-full"
          placeholder={t("input.address.placeholder")}
          onChange={(e) => setAddressId(e)}
          options={addresses.map((item) => ({
            value: item.id,
            label: item.value,
          }))}
          value={addressId}
          size="sm"
        />
        <div className="text-center pt-2">
          <span
            className="underline cursor-pointer"
            onClick={() => setOpenModal(true)}
          >
            {t("label.createNewAddress")}
          </span>
        </div>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onOk={handleCreateAddress}
          onCancel={handleCloseModal}
          width={768}
          footer={
            <>
              <StyledButton onClick={handleCloseModal} className="outline">
                {t("button.cancel")}
              </StyledButton>
              <StyledButton
                disabled={!addressReq.value}
                onClick={handleCreateAddress}
                className="primary"
              >
                {t("button.ok")}
              </StyledButton>
            </>
          }
        >
          <div className="pt-4">
            <AddressInput onUpdateData={(data) => setAddressReq(data)} />
          </div>
          {/* <div className="pt-4">
          <div>
            <MTInput
              label={t("input.address.label")}
              value={addressReq.value}
              onChangeText={(value) =>
                setAddressReq({
                  ...addressReq,
                  value: value,
                })
              }
            />
          </div>
          <div className="pt-4">
            <Checkbox
              checked={addressReq.primary}
              onChange={(e) =>
                setAddressReq({
                  ...addressReq,
                  primary: e.target.checked,
                })
              }
            >
              {t("label.defaultAddress")}
            </Checkbox>
          </div>
        </div> */}
        </Modal>
      )}
    </>
  );
}
