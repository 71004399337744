import classNames from "classnames";
import { useTranslation } from "react-i18next";

import {
  DISPLAY_PHONE_NUMBER,
  EMAIL,
  PHONE_NUMBER,
  WEB_URL,
} from "@app-constants/common";

interface ContentData {
  title?: string;
  href?: string;
  items?: {
    title?: string;
    content?: any[];
    titleId?: string;
    inshort?: string;
  }[];
}
export interface PolicyContentData {
  content: {
    [key: string]: ContentData;
  };
  hideIndex?: boolean;
  hideTableContent?: boolean;
  titleStyles?: string;
  subTitleStyles?: string;
}

export const PHONE_NUMBER_LINK = `<a href="tel:${PHONE_NUMBER}">${DISPLAY_PHONE_NUMBER}</a>`;

export default function PolicyContent({ data }: { data: PolicyContentData }) {
  const { t } = useTranslation();
  const { content, hideIndex, hideTableContent, titleStyles, subTitleStyles } =
    data;

  return (
    <>
      {!hideTableContent && (
        <>
          <div className="mt-title-xl pb-4 pt-4" id="table-content">
            {t("page.terms.tableContent")}
          </div>
          <ul className="pb-4 mt-table-content">
            {Object.keys(content).map((key: string, index: number) => (
              <li key={index} className="mt-text-body-lg underline ">
                <a href={"#" + content[key]?.href}>
                  {index + 1}. {content[key]?.title}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}

      {Object.keys(content).map((key: string, index: number) => (
        <div key={index} id={content[key].href}>
          {content[key].title && (
            <div className={classNames("mt-title-xl pt-6", titleStyles)}>
              {!hideIndex ? `${index + 1}. ` : ""}
              {content[key].title}
            </div>
          )}
          {(content[key].items || []).map((item: any, subindex: number) => (
            <div key={subindex}>
              {item.title && (
                <div
                  className={classNames("mt-title-xl pt-4", subTitleStyles)}
                  id={item.titleId}
                >
                  {item.title}
                </div>
              )}
              {item.inshort && (
                <div className="mt-text-body-lg pt-4">
                  <em>
                    <strong>{t("page.privacy.inShort")} </strong>
                    {item.inshort}
                  </em>
                </div>
              )}
              {item.content &&
                item.content.map((itm: any, index: number) => (
                  <div key={index} className="mt-text-body-lg pt-4">
                    {typeof itm === "string" ? (
                      <div dangerouslySetInnerHTML={{ __html: itm }}></div>
                    ) : (
                      itm
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

export const getEmailLink = (
  type: "info" | "destouches" | "contact" | "privacy"
) => {
  return `<a href="mailto:${EMAIL[type]}">${EMAIL[type]}</a>`;
};

export const generateLink = (data: {
  type: "internal" | "external";
  href?: string;
  link?: string;
  path?: string;
  alias?: string;
  classNames?: string;
}) => {
  const targetLink = data.href
    ? `#${data.href}`
    : data.path
    ? `${WEB_URL}${data.path}`
    : data.link || WEB_URL;

  return `<a href="${targetLink}" class="${data.classNames || ""}" target="${
    data.href ? "_parent" : "_blank "
  }">
      ${data.alias || targetLink}
    </a>`;
};

export const generateList = (
  list: any[],
  classNames?: string,
  ulClassNames?: string
) => {
  return (
    <ul className={ulClassNames || "list-disc list-inside"}>
      {list.map((item, index) => {
        return (
          <li className={classNames + " first:pt-0"} key={index}>
            {generateItem(item)}
          </li>
        );
      })}
    </ul>
  );
};
const innerSpanHtml = (value: string) => {
  return value.includes("</") ? (
    <span dangerouslySetInnerHTML={{ __html: value }} />
  ) : (
    value
  );
};

export const generateItem = (item: any) => {
  return (
    <span>
      {typeof item === "string" ? (
        innerSpanHtml(item)
      ) : (
        <>
          {item.strong && (
            <strong
              id={item.strongId}
              className={item.strongUnderline && "underline"}
            >
              {item.strong}
            </strong>
          )}
          {item.em && <em>{item.em}</em>}
          {item.content && (
            <span className={item.contentUnderline && "underline"}>
              {" "}
              {innerSpanHtml(item.content)}
            </span>
          )}
        </>
      )}
    </span>
  );
};

export const generateTable = (header: string[], row: string[][]) => {
  return (
    <div>
      <table className="text-left border-collapse border border-slate-500">
        <thead>
          <tr>
            {header?.map((text: string, j: number) => (
              <th key={j} className="border border-slate-600 p-2 ">
                {text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {row.map((row: string[], j: number) => (
            <tr key={j}>
              {row.map((item: string, z: number) => (
                <td
                  key={z}
                  className="border border-slate-700 p-2 last:text-center"
                >
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
