import React from "react";

export default function CardOutline() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82043 8.41663C6.03259 8.41663 4.58325 9.86596 4.58325 11.6538V28.3205C4.58325 30.1083 6.03259 31.5577 7.82043 31.5577H32.1794C33.9673 31.5577 35.4166 30.1083 35.4166 28.3205V11.6538C35.4166 9.86596 33.9673 8.41663 32.1794 8.41663H7.82043ZM2.08325 11.6538C2.08325 8.48525 4.65188 5.91663 7.82043 5.91663H32.1794C35.348 5.91663 37.9166 8.48525 37.9166 11.6538V28.3205C37.9166 31.489 35.348 34.0577 32.1794 34.0577H7.82043C4.65188 34.0577 2.08325 31.489 2.08325 28.3205V11.6538Z"
        fill="#111318"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6666 16.1091H3.33325V13.6091H36.6666V16.1091Z"
        fill="#111318"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49268 23.5126C8.49268 22.8222 9.05232 22.2626 9.74268 22.2626H13.6074C14.2977 22.2626 14.8574 22.8222 14.8574 23.5126V25.1229C14.8574 25.8132 14.2977 26.3729 13.6074 26.3729H9.74268C9.05232 26.3729 8.49268 25.8132 8.49268 25.1229V23.5126Z"
        fill="#111318"
      />
    </svg>
  );
}
