import { useTranslation } from "react-i18next";

import { QnASection } from "@app-components/faq/FaqSection";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import { preOrderPolicy } from "@app-constants/order";
import { getRoutes } from "@app-utils/routes";

export default function PreOrderPolicyPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  return (
    <div className="container faq faq-detail pre-order">
      <MTBreadcrumb items={[routes.home, routes.preOrder]} />
      <div className="faq-detail-container">
        <div className="wrapper">
          <div className="faq-detail-header">
            <div className="faq-detail-header-container">
              <div className="faq-detail-title">
                {t("title.preOrderPolicy")}
              </div>
              <div className="faq-detail-subtitle">
                {t("page.preOrder.subtitle")}
              </div>
            </div>
          </div>
          <QnASection item={preOrderPolicy} showHeader={false} />
        </div>
      </div>
    </div>
  );
}
