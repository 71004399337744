import { useAtom } from "jotai";
import Lottie from "lottie-react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

import { mansoryLayoutAtom } from "../../jotai/layout";
import UseRing from "./mansory-detail/UseRing";
import comingsoonAnimation from "../../assets/lotties/coming-soon.json";

export default function HowToUseMansoryDetail() {
  const [mansoryLayout, setMansoryLayout] = useAtom(mansoryLayoutAtom);
  const [layout, setLayout] = useState("");

  useEffect(() => {
    if (mansoryLayout) {
      setLayout(mansoryLayout);
    }
  }, [mansoryLayout]);

  const renderContent = () => {
    switch (layout) {
      case "use-ring":
        return <UseRing />;

      default:
        return <Lottie className="container flex items-center justify-center h-full" animationData={comingsoonAnimation} loop />;
    }
  };
  return (
    <div className={classNames("lp-mansory-detail", { show: !!mansoryLayout })}>
      <div className="inner">
        <div className="container relative">
          <CloseOutlined
            className="close-icon"
            onClick={() => {
              setMansoryLayout("");
              setTimeout(() => {
                setLayout("");
              }, 1000);
            }}
          />
        </div>
        <div className="h-full">{renderContent()}</div>
      </div>
    </div>
  );
}
