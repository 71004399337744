import { Alert } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import validator from "validator";

import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import MTPasswordInput from "@app-components/input/MTPasswordInput";
import { LocalStorageKeys } from "@app-constants/common";
import { signinPayloadAtom, useAuthWriteOnly } from "@app-jotai/auth";
import { formatPhoneNumber } from "@app-utils/number";
import { PATHS } from "@app-utils/routes";

export default function Login() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { onSignin, clearSigninPayload, setToken } = useAuthWriteOnly();

  const signinPayload = useAtomValue(signinPayloadAtom);

  const [signin, setSignin] = useState({
    phone: "",
    password: "",
  });

  const [isRemember, setIsRemember] = useState(false);

  const validPhone = validator.isMobilePhone(signin.phone, "vi-VN");

  useEffect(() => {
    handleGetSavedAccount();
    return () => {
      clearSigninPayload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signinPayload.success) {
      if (isRemember) {
        window.localStorage.setItem(
          LocalStorageKeys.SAVED_ACCOUNT,
          signin.phone
        );
      } else {
        window.localStorage.removeItem(LocalStorageKeys.SAVED_ACCOUNT);
      }

      setToken(signinPayload?.data?.accessToken as string);
      const redirect = searchParams.get("redirect");
      navigate(redirect || "/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signinPayload.success]);

  const handleGetSavedAccount = () => {
    const phone = window.localStorage.getItem(LocalStorageKeys.SAVED_ACCOUNT);
    if (phone) {
      setIsRemember(true);
      setSignin((prev) => ({
        ...prev,
        phone,
      }));
    }
  };

  const handleSignin = () => {
    if (signin.phone && signin.password) {
      const formattedPhone = formatPhoneNumber(signin.phone);
      onSignin({ username: formattedPhone, password: signin.password });
    }
  };

  const handleOnNavigateSignup = () => {
    navigate(PATHS.register);
  };

  const renderContent = () => {
    if (signinPayload.success) return null;
    return (
      <>
        <div className="input relative pb-10">
          <MTInput
            label={t("input.phoneNumber.label")}
            value={signin.phone}
            onChangeText={(value) =>
              setSignin((prev) => ({
                ...prev,
                phone: value,
              }))
            }
            error={signin.phone && !validPhone ? t("content.invalidPhone") : ""}
          />
        </div>
        <div className="input relative pb-6">
          <MTPasswordInput
            label={t("input.password.label")}
            value={signin.password}
            onChangeText={(value) =>
              setSignin((prev) => ({
                ...prev,
                password: value,
              }))
            }
          />
        </div>

        {signinPayload?.error && (
          <div className="pb-6 text-left">
            <Alert message={t(signinPayload.error)} type="error" showIcon />
          </div>
        )}

        <div className="flex items-center justify-between pb-[24px]">
          <Link to={PATHS.forgotPassword}>
            <span className="cursor-pointer mt-text-button-md">
              {t("button.forgotPassword")}
            </span>
          </Link>
        </div>

        <div className="text-center">
          <StyledButton
            className="w-full primary"
            onClick={handleSignin}
            loading={signinPayload.loading}
            disabled={!signin.phone || !signin.password || !validPhone}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.signin")}
            </span>
          </StyledButton>

          <div className="py-[32px]">
            <span className="mt-text-body-xl">{t("content.needAccount")}</span>
          </div>
          <StyledButton
            className="outline w-full"
            onClick={handleOnNavigateSignup}
          >
            <span className="mt-text-button-xl py-[13px]">
              {t("button.signup")}
            </span>
          </StyledButton>
        </div>
      </>
    );
  };

  return (
    <div className="auth-page container flex items-center justify-center pt-[20px] pb-[40px]">
      <div className="">
        <div className="">
          <div className="form min-w-[360px] lg:min-w-[464px]">
            <div className="auth-page__title">
              {signinPayload.success
                ? t("title.welcomeToMT")
                : t("title.signin")}
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}
