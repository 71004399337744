import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AfterSalesSvg } from "@app-assets/svg/afterSales.svg";
import { ReactComponent as ShipmentCheckSvg } from "@app-assets/svg/shipmentCheck.svg";
import { ReactComponent as ShippingTruckSvg } from "@app-assets/svg/shippingTruck.svg";
import {
  FaqItemProps,
  OptionData,
  QnAItemProps,
  QnASection,
} from "@app-components/faq/FaqSection";
import ErrorAnimation from "@app-components/lotties/ErrorAnimation";
import { SolidIcon } from "@app-components/svg/icon";
import { faqData } from "@app-constants/faq";
import { PATHS } from "@app-utils/routes";

export default function Faq() {
  const { t } = useTranslation();
  const [filteredQuestion, setFilteredQuestion] = useState(faqData);
  const [searching, setSearching] = useState("");

  const questions = faqData.reduce(
    (result: OptionData[], item: FaqItemProps) => {
      result.push({ value: t(item.label), label: t(item.label) });
      item.items.forEach((itm: QnAItemProps) => {
        result.push({ value: t(itm.label), label: t(itm.label) });
      });
      return result;
    },
    []
  );

  const handleSearchQuestion = (value: string) => {
    setSearching(value);
    if (!!value) {
      const newFaq = faqData.reduce(
        (result: FaqItemProps[], section: FaqItemProps) => {
          if (
            t(section.label).toLowerCase().indexOf(value.toLowerCase()) !== -1
          ) {
            result.push(section);
          } else {
            const matches = section.items.filter(
              (item: QnAItemProps) =>
                t(item.label).toLowerCase().indexOf(value.toLowerCase()) !== -1
            );
            if (matches.length > 0) {
              const newSection = { ...section, items: matches };
              result.push(newSection);
            }
          }
          return result;
        },
        []
      );
      setFilteredQuestion(newFaq);
    } else {
      setFilteredQuestion(faqData);
    }
  };

  const faqs = [
    {
      icon: <ShipmentCheckSvg />,
      href: PATHS.productSupport,
      title: t("page.faq.section1.title"),
      subtitle: t("page.faq.section1.subtitle"),
    },
    {
      icon: <ShippingTruckSvg />,
      href: PATHS.deliverySupport,
      title: t("page.faq.section2.title"),
      subtitle: t("page.faq.section2.subtitle"),
    },
    {
      icon: <AfterSalesSvg />,
      href: PATHS.afterSalesSupport,
      title: t("page.faq.section3.title"),
      subtitle: t("page.faq.section4.subtitle"),
    },
    {
      icon: <SolidIcon.HandShake />,
      href: PATHS.partnershipSupport,
      title: t("page.faq.section4.title"),
      subtitle: t("page.faq.section4.subtitle1"),
    },
  ];

  return (
    <div className="container faq">
      <div className="faq__filter">
        <div className="faq__filter-header">
          <h1 className="faq__filter-title">{t("page.faq.howCanWeHelp")}</h1>

          <AutoComplete
            allowClear
            style={{ width: "100%", margin: "16px 0" }}
            options={questions}
            onSelect={handleSearchQuestion}
            onSearch={handleSearchQuestion}
            filterOption={(inputValue, option) =>
              option!.value.indexOf(inputValue.toLowerCase()) !== -1
            }
            value={searching}
          >
            <Input
              className="faq__filter-input "
              bordered={false}
              size="large"
              placeholder={t("page.faq.searchForArticles")}
              prefix={<SearchOutlined />}
            />
          </AutoComplete>
        </div>

        <div className="faq__filter-label">
          {!searching
            ? t("page.faq.searchForSolutions")
            : t("page.faq.searchResults")}
        </div>
        {!searching && (
          <>
            <div className="faq__filter-sections">
              {faqs.map((item) => (
                <a
                  key={item.title}
                  href={item.href}
                  className="faq__filter-card"
                >
                  <div className="faq__filter-icon">{item.icon}</div>
                  <div className="faq__filter-card-title">{item.title}</div>
                  <div className="faq__filter-card-content">
                    {item.subtitle}
                  </div>
                </a>
              ))}
            </div>
            <div className="faq__email">
              <div className="faq__email-title">
                {t("page.faq.stillNeedHelp")}
              </div>
              <a
                className="faq__email-button mt-button primary"
                href="mailto:contact@manytouches.com"
              >
                <span>{t("button.emailUs")}</span>
              </a>
            </div>
          </>
        )}
      </div>

      <div className="faq__content">
        {searching && (
          <div className="faq__content-container">
            {filteredQuestion.length > 0 ? (
              filteredQuestion.map((item) => (
                <QnASection key={item.label} item={item} />
              ))
            ) : (
              <div className="flex flex-col items-center">
                <ErrorAnimation />

                <div className="text-danger">{t("page.faq.notFound")}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
