import { useTranslation } from "react-i18next";

import BoxComponentPng from "@app-assets/images/box-components.png";
import MissionImage from "@app-assets/images/website-home-mission-icon.png";
import PresentationImage from "@app-assets/images/website-home-representation-icon.png";
import VisionImage from "@app-assets/images/website-home-vision-icon.png";
import People from "@app-components/aboutUs/People";
// import StyledButton from "@app-components/button/StyledButton";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import MTVideo from "@app-components/media/MTVideo";
import { getRoutes } from "@app-utils/routes";

export default function AboutUsPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  return (
    <div className="mt-about-us">
      <div className="container">
        <MTBreadcrumb items={[routes.home, routes.aboutUs]} />
      </div>
      <div>
        <div className="mt-about-us__container gap-[60px] xl:gap-[160px]">
          <div className="mt-about-us__section-1 container">
            <div className="flex flex-col desc">
              <div className="mt-display-sm">
                {t("page.aboutUs.aboutUsSection.title")}
              </div>
              <div className="mt-text-body-xl">
                {t("page.aboutUs.aboutUsSection.content")}
              </div>
            </div>
            <div className="video">
              <MTVideo
                id="product-detail-video"
                videoUrl="https://video.wixstatic.com/video/9a71f9_e9e5c7ec4bc240a38bb0ba5acfeafd49/1080p/mp4/file.mp4"
                thumbnail={BoxComponentPng}
              />
            </div>
          </div>

          <div className="mt-about-us__section-2">
            <div className="container">
              <div className="mt-display-sm">
                {t("page.aboutUs.trifecta.title")}
              </div>
              <div className="mt-about-us__list">
                {/* <KnowMore /> */}
                <div className="mt-about-us__card">
                  <div className="image">
                    <img src={VisionImage} alt="" />
                  </div>
                  <div className="mt-heading-md">
                    {t("page.aboutUs.trifecta.vision.title")}
                  </div>
                  <div className="mt-text-body-xl  body">
                    {t("page.aboutUs.trifecta.vision.content")}
                  </div>
                </div>
                <div className="mt-about-us__card">
                  <div className="image">
                    <img src={MissionImage} alt="" />
                  </div>
                  <div className="mt-heading-md heading">
                    {t("page.aboutUs.mission.title")}
                  </div>
                  <div className="mt-text-body-xl body">
                    {t("page.aboutUs.mission.content")}
                  </div>
                </div>
                <div className="mt-about-us__card">
                  <div className="image">
                    <img src={PresentationImage} alt="" />
                  </div>
                  <div className="mt-heading-md">
                    {t("page.aboutUs.presentation.title")}
                  </div>
                  <div className="mt-text-body-xl  body">
                    {t("page.aboutUs.presentation.content")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-about-us__section-3">
            <div className="container">
              <div className="flex flex-col lg:flex-row">
                <div className="">
                  <div className="text-[40px] leading-[1.2] font-semibold pb-[24px] text-center">
                    {t("page.aboutUs.section3.title")}
                  </div>
                  <div className="w-full flex justify-center">
                    <p className="text-[24px] leading-[1.5] text-center xl:w-10/12">
                      {t("page.aboutUs.section3.p1")}
                      <br />
                      {/* {t("page.aboutUs.section3.p2")} */}
                    </p>
                  </div>
                  {/* <div className="[&_.mt-button]:h-[56px] [&_.mt-button]:text-[20px] [&_.mt-button]:px-[20px] justify-center flex">
                    <StyledButton className="primary min-w-[286px]">
                      {t("button.learnAboutTheRing")}
                    </StyledButton>
                  </div> */}
                </div>
                {/* Avatar */}

                {/* <div className="mx-auto flex-shrink-0 text-center">
                  <div className="max-w-[340px] xl:max-w-[560px] mx-auto w-full bg-silver-200 rounded-[12px] overflow-hidden">
                    <div className="relative pt-[100%] flex justify-center items-center">
                      <div className="absolute top-0 left-0 h-full">
                        <img
                          src={PoloImage}
                          alt=""
                          className="h-full object-cover"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[340px] xl:max-w-[590px] w-full text-center text-[20px] font-medium pt-[16px]">
                    {t("page.aboutUs.section3.name")}
                  </div>
                </div> */}
              </div>
            </div>

            <People />
          </div>
          {/* <div className="mt-about-us__section-4"></div> */}
        </div>
      </div>
    </div>
  );
}
