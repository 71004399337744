import RingOverview from "@app-components/ringFunction/RingOverview";

import Func2 from "../../../assets/images/for-access-card.png";
import Func3 from "../../../assets/images/for-payment.png";

export default function UseRing() {
  return (
    <div className="container use-ring">
      <div className="use-ring__content">
        <div className="use-ring__title">
          Accessing devices
          <br />
          and Making payments
        </div>
        <div className="use-ring__desc">
          <p>
            <strong>1.</strong>
            Make sure that all the cards you want to use with your ring have
            been added to the app.
          </p>

          <p>
            <strong>2.</strong> To use with your ring, tap the top part of your
            ring (where the Many Touches logo is located) on the card reader, or
            the bottom part of your ring on the payment terminal.
          </p>

          <p>
            <strong>3.</strong> The card reader or payment terminal should
            process the information from your ring as if it was a physical card.
          </p>

          <p>
            <strong>4.</strong> If the card reader or payment terminal requires
            a signature or personal identification number (PIN), follow the
            prompts on the device to complete the transaction.
          </p>
        </div>
      </div>

      <div className="lp-function" id="function">
        <div className="container">
          <div className="lp-function__list">
            <RingOverview />
            <div className="lp-function__list-item lp-function__list-item-2">
              <div className="content">
                <div className="lp-function__text">For Access card</div>
                <img src={Func2} alt="" />
              </div>
            </div>
            <div className="lp-function__list-item lp-function__list-item-3">
              <div className="content">
                <div className="lp-function__text">For Payment</div>
                <img src={Func3} alt="" />
              </div>
            </div>
            {/* <div className="flex"></div> */}
          </div>
          <div className="lp-function__note">
            *When you wear The ring with logo on top
          </div>
        </div>
      </div>
    </div>
  );
}
