import { useTranslation } from "react-i18next";

import CreateAccountSvg from "@app-assets/images/userGuide/create-account.svg";
import LinkBankSvg from "@app-assets/images/userGuide/link-bank.svg";
import OpenAppSvg from "@app-assets/images/userGuide/open-app.svg";
import PaymentSvg from "@app-assets/images/userGuide/payment.svg";
import RingListSvg from "@app-assets/images/userGuide/ring-list.svg";
import SelectLinkBankSvg from "@app-assets/images/userGuide/select-link-bank.svg";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import GuideImage from "@app-components/userGuide/GuideImage";
import { getRoutes } from "@app-utils/routes";

export default function ForPaymentPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  const steps = [
    {
      title: t("page.guide.step", [1]),
      src: OpenAppSvg,
      description: t("page.guide.download"),
    },
    {
      title: t("page.guide.step", [2]),
      src: CreateAccountSvg,
      description: t("page.guide.createAccount"),
    },
    {
      title: t("page.guide.step", [3]),
      src: RingListSvg,
      description: t("page.guide.ringList"),
    },
    {
      title: t("page.guide.step", [4]),
      src: PaymentSvg,
      description: t("page.guide.selectPaymentTab"),
    },
    {
      title: t("page.guide.step", [5]),
      src: SelectLinkBankSvg,
      description: t("page.guide.selectLinkBank"),
    },
    {
      title: t("page.guide.step", [6]),
      src: LinkBankSvg,
      description: t("page.guide.linkBank"),
    },
  ];
  return (
    <div className="container mt-ugd faq faq-detail">
      <MTBreadcrumb items={[routes.home, routes.forPayment]} />
      <div className="mt-ugd">
        <div className="mt-title">
          <span>{t("page.product.guide.money")}</span>
        </div>

        <div className="mt-ugd__link-imgs">
          {steps.map((step) => {
            return (
              <GuideImage
                key={step.title}
                className="ur-img"
                title={step.title}
                src={step.src}
                description={step.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
