import { useTranslation } from "react-i18next";

import ContactInfo from "@app-components/common/ContactInfo";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import { OutlineIcon } from "@app-components/svg/icon";
import { getRoutes } from "@app-utils/routes";

export default function ContactPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);
  const renderAddress = () => {
    return <ContactInfo />;
  };

  return (
    <div className="container mt-contact">
      <MTBreadcrumb items={[routes.home, routes.contactsUs]} />
      <div className="mt-contact__container">
        <div className="flex flex-col mt-contact__card">
          <div className="mt-contact__title">
            <div className="mt-heading-lg">
              {t("page.contact.customerSupport.title")}
            </div>
            <OutlineIcon.User />
          </div>
          <div className="mt-text-body-xl">
            <div className="mt-text-body-xl">
              {t("page.contact.customerSupport.content")}
            </div>
            <div className="pt-4">
              {t("page.contact.customerSupport.contactUs")}
            </div>
          </div>
          <div className="flex-1"></div>
          {renderAddress()}
        </div>
        <div className="flex flex-col mt-contact__card">
          <div className="mt-contact__title">
            <div className="mt-heading-lg">
              {t("page.contact.collaboration.title")}
            </div>
            <OutlineIcon.Partner />
          </div>
          <div className="mt-text-body-xl">
            <div>{t("page.contact.collaboration.content")}</div>
            <div className="pt-4">
              {t("page.contact.collaboration.contactUs")}
            </div>
          </div>
          <div className="flex-1"></div>
          {renderAddress()}
        </div>
      </div>
    </div>
  );
}
