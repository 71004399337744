export function scrollTrigger(selector: string, options = {}, cb: () => void) {
  let els: any = document.querySelectorAll(selector);
  if (els) {
    els = Array.from(els);
    els.forEach((el: any) => {
      addObserver(el, options, cb);
    });
  }
}

export function addObserver(el: any, options = {}, cb: () => void) {
  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        cb?.();
      }
    });
  }, options); // Passing the options object to the observer
  observer.observe(el);
}

export const scrollToElementId = (elementId: string) => {
  const el = document.getElementById(elementId);
  if (el) {
    const top = Number(el?.offsetTop);
    window.scroll({
      top: top - 82,
      left: 0,
      behavior: "smooth",
    });
  }
};
