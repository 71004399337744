import { useTranslation } from "react-i18next";

import MansoryImage2 from "@app-assets/images/get-ring-size-2.png";
import RingOverview from "@app-components/ringFunction/RingOverview";
import BuildingOutline from "@app-components/svg/BuildingOutline";
import CardOutline from "@app-components/svg/CardOutline";
import { SolidIcon } from "@app-components/svg/icon";
import { PATHS } from "@app-utils/routes";

export default function UserGuide() {
  const { t } = useTranslation();

  return (
    <div className="mt-ug">
      <div className="mt-ug__list">
        <div className="mt-ug__group">
          <a className="mt-ug__item" href={`${PATHS.useRing}`}>
            <div className="inner">
              <div className="mt-ug__title">
                {t("page.product.guide.howToUseTheRing")}
              </div>
              {/* <div className="mt-ug__subtitle">
                Navigating the dual features of your smart ring.
              </div> */}
              <div className="mt-ug__image">
                <RingOverview />
              </div>
              <div className="plus-icon-filled">
                <SolidIcon.PlusCircle />
              </div>
            </div>
          </a>
        </div>
        <div className="mt-ug__group">
          <a className="mt-ug__item" href={PATHS.getRingSize}>
            <div className="inner">
              <div className="mt-ug__title">
                {t("page.product.guide.howToGetYourRingSize")}
              </div>
              {/* <div className="mt-ug__subtitle">
                Your ultimate guide to getting the perfect ring fit.
              </div> */}
              <div className="mt-ug__image">
                <img src={MansoryImage2} alt="mansory" />
              </div>
              <div className="plus-icon-filled">
                <SolidIcon.PlusCircle />
              </div>
            </div>
          </a>
          {/* item 2 */}
        </div>
        <div className="mt-ug__group col-3">
          {/* item 3 */}
          <a className="mt-ug__item item-3" href={PATHS.forPayment}>
            <div className="inner">
              <div className="small-header-container w-full">
                <div className="flex-1">
                  <div className="mt-ug__title text-left">
                    {t("page.product.guide.money")}
                  </div>
                  {/* <div className="mt-ug__subtitle text-left">
                    Adding cards to your ring for ultimate convenience.
                  </div> */}
                </div>
                <div>
                  <CardOutline />
                </div>
              </div>
              <div className="plus-icon-filled">
                <SolidIcon.PlusCircle />
              </div>
            </div>
          </a>
          {/* item 3 */}

          {/* item 4 */}
          <a className="mt-ug__item item-4" href={PATHS.accessControl}>
            <div className="inner">
              <div className="small-header-container w-full">
                <div className="flex-1">
                  <div className="mt-ug__title text-left">
                    {t("page.product.guide.accessKey")}
                  </div>
                  {/* <div className="mt-ug__subtitle text-left">
                    Adding keys to your ring for ultimate convenience.
                  </div> */}
                </div>
                <div>
                  <BuildingOutline />
                </div>
              </div>
              <div className="plus-icon-filled">
                <SolidIcon.PlusCircle />
              </div>
            </div>
          </a>
          {/* item 4 */}
        </div>
      </div>
    </div>
  );
}
