import classNames from "classnames";
import { useAtomValue } from "jotai";

import Header from "@app-components/header/Header";
import BannerVideo from "@app-components/landing-page/BannerVideo";
import FingerTip from "@app-components/landing-page/FingerTip";
import LPFooter from "@app-components/landing-page/Footer";
import FunctionIntroSection from "@app-components/landing-page/FunctionIntroSection";
import HowToUseMansoryDetail from "@app-components/landing-page/HowToUseMansoryDetail";
// import KnowMore from "@app-components/landing-page/KnowMore";
import Lifestyle from "@app-components/landing-page/Lifestyle";
import UnlockLimitless from "@app-components/landing-page/UnlockLimitless";
import UserGuide from "@app-components/userGuide/UserGuide";
import useScrollToTop from "@app-hook/useScrollToTop";
import { mansoryLayoutAtom } from "@app-jotai/layout";

export default function NewLandingPage() {
  useScrollToTop();
  const mansoryLayout = useAtomValue(mansoryLayoutAtom);

  return (
    <div className="landing-page new">
      <Header />

      <div
        className={classNames("landing-page__content", {
          "move-to-left": !!mansoryLayout,
        })}
      >
        <BannerVideo />

        <FingerTip />

        <FunctionIntroSection />

        {/* <RingSpecifications /> */}

        <UnlockLimitless />

        <div className="container lp-user-guide">
          <UserGuide />
        </div>

        <Lifestyle />

        {/* <CustomerComments /> */}

        {/* <KnowMore /> */}

        <LPFooter />
      </div>

      <HowToUseMansoryDetail />
    </div>
  );
}
