import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

import PaymentThumb from "../../assets/images/payment-thumb.png";
import UnlockThumb from "../../assets/images/unlock-thumb.png";
import FunctionIntroItem from "./FunctionIntroItem";

export default function FunctionIntroSection() {
  const { t } = useTranslation();
  return (
    <div className="lp-function-intro-section">
      <div className="container ">
        <div className="function-intro-list">
          <MediaQuery minWidth={992}>
            <FunctionIntroItem
              title={t("page.landing.functionIntro.func1.title")}
              desc={t("page.landing.functionIntro.func1.desc")}
              id="unlock"
              videoUrl="https://video.wixstatic.com/video/9a71f9_e983d07d0ac64d508aed2b3ad303ce35/1080p/mp4/file.mp4"
              thumbnail={UnlockThumb}
            />
            <FunctionIntroItem
              title={t("page.landing.functionIntro.func2.title")}
              desc={t("page.landing.functionIntro.func2.desc")}
              id="payment"
              videoUrl="https://video.wixstatic.com/video/9a71f9_116bb798bf464a7b98d0afbc62464907/1080p/mp4/file.mp4"
              thumbnail={PaymentThumb}
            />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <FunctionIntroItem
              title={t("page.landing.functionIntro.func1.title")}
              desc={t("page.landing.functionIntro.func1.desc")}
              id="unlock-m"
              videoUrl="https://video.wixstatic.com/video/9a71f9_55fc9a26515347c6baaa0b75e4ea1891/1080p/mp4/file.mp4"
              thumbnail={UnlockThumb}
            />
            <FunctionIntroItem
              title={t("page.landing.functionIntro.func2.title")}
              desc={t("page.landing.functionIntro.func2.desc")}
              id="payment-m"
              videoUrl="https://video.wixstatic.com/video/9a71f9_08656413f8e2488d824a6f899de673d4/1080p/mp4/file.mp4"
              thumbnail={PaymentThumb}
            />
          </MediaQuery>
        </div>
      </div>
    </div>
  );
}
