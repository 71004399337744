import { atom } from "jotai";
import { atomService } from "../utils";
import { listProductsPayloadAtom, productDetailPayloadAtom } from "./atoms";
import productServices from "../../services/productServices";

const getProductsApiAtom = atom(null, (get, set) =>
  atomService(listProductsPayloadAtom, productServices.getList, get, set)
);

const getProductApiAtom = atom(null, (get, set, id: number) =>
  atomService(
    productDetailPayloadAtom,
    () => productServices.getDetail(id),
    get,
    set
  )
);

export { getProductsApiAtom, getProductApiAtom };
