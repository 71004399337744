import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as EmailSvg } from "@app-assets/svg/email.svg";
import { ReactComponent as LocationSvg } from "@app-assets/svg/location.svg";
import { ReactComponent as PhoneSvg } from "@app-assets/svg/phone.svg";
import { SolidIcon } from "@app-components/svg/icon";
import {
  CONTACT_EMAIL,
  DISPLAY_PHONE_NUMBER,
  PHONE_NUMBER,
} from "@app-constants/common";

export default function ContactInfo({ footer = false }: { footer?: boolean }) {
  const { t } = useTranslation();
  return (
    <div className="contact">
      {footer && (
        <div className="contact-group">
          <span className="contact-label">
            <SolidIcon.Enterprise />
            {t("contact.enterpriseCode")}
          </span>
          <span className="contact-content">
            {t("contact.enterpriseContent")}
          </span>
        </div>
      )}
      <div className="contact-group">
        <span className="contact-label">
          <LocationSvg />
          {t(footer ? "label.headquarter" : "label.address")}
        </span>
        <span className="contact-content">
          {!footer && t("contact.companyName") + ", "}
          {t("contact.companyAddressContent")}
        </span>
      </div>
      <div className={classNames("contact-group", footer && "order-2")}>
        <span className="contact-label">
          <PhoneSvg />
          {t("label.mobile")}
        </span>
        <a href={`tel:${PHONE_NUMBER}`} className="contact-content">
          {DISPLAY_PHONE_NUMBER}
        </a>
      </div>
      <div className="contact-group">
        <span className="contact-label">
          <EmailSvg />
          Email
        </span>
        <a href={`mailto:${CONTACT_EMAIL}`} className="contact-content">
          {CONTACT_EMAIL}
        </a>
      </div>
    </div>
  );
}
