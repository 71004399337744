import React from "react";
import { useTranslation } from "react-i18next";

export default function UnlockLimitless() {
  const { t } = useTranslation();
  return (
    <div className="lp-limitless">
      <div className="container">
        <div className="lp-limitless__title">
          {t("page.landing.unlockLimitless.title")}
        </div>
        <div className="lp-limitless__description">
          {t("page.landing.unlockLimitless.desc")}
        </div>
      </div>
    </div>
  );
}
