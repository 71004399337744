import MTVideo from "../media/MTVideo";

export default function FunctionIntroItem({
  id,
  title,
  desc,
  videoUrl,
  thumbnail,
  paddingTop,
}: {
  id?: string;
  title: string;
  desc: string | JSX.Element;
  videoUrl: string;
  thumbnail?: any;
  paddingTop?: string;
}) {
  return (
    <div className="function-intro" id={id}>
      <div className="inner">
        <div className="function-intro__left">
          <div className="content">
            <div className="function-intro__title whitespace-break-spaces">
              {title}
            </div>
            <div className="function-intro__desc whitespace-break-spaces">
              {desc}
            </div>
          </div>
        </div>
        <div className="function-intro__right">
          <MTVideo
            paddingTop={paddingTop}
            id={id}
            videoUrl={videoUrl}
            thumbnail={thumbnail}
          />
        </div>
      </div>
    </div>
  );
}
