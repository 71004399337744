import { throwErrorMessage } from "@app-utils/errorHandler";

import { baseApi } from "./common";

const getList = async (): Promise<any> => {
  try {
    const response = await baseApi.get(`products`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getDetail = async (id: number): Promise<any> => {
  try {
    const response = await baseApi.get(`products/${id}`);
    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};
const productServices = {
  getList,
  getDetail,
};

export default productServices;
