import { throwErrorMessage } from "@app-utils/errorHandler";

import { CreateOrderReq } from "../interface/order";
import { baseApi } from "./common";

const getOrdersList = async () => {
  try {
    const response = await baseApi.get(`orders`);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createOrder = async (payload: CreateOrderReq) => {
  try {
    const response = await baseApi.post(`orders`, payload);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const createOrderAnonymous = async (payload: CreateOrderReq) => {
  try {
    const response = await baseApi.post(`orders/anonymous`, payload);

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const getOrderDetail = async (id: string, isAnoymous?: boolean) => {
  try {
    const response = await baseApi.get(
      `orders/${isAnoymous ? "anonymous/" : ""}${id}`
    );

    return response.data;
  } catch (error: any) {
    throw throwErrorMessage(error);
  }
};

const orderServices = {
  getOrdersList,
  createOrder,
  createOrderAnonymous,
  getOrderDetail,
};

export default orderServices;
