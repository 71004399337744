import { YoutubeOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LogoSaleNoti from "@app-assets/images/logoSaleNoti1.png";
import { ReactComponent as BadgeAndroidSvg } from "@app-assets/svg/badgeAndroid.svg";
import { ReactComponent as BadgeIOSSvg } from "@app-assets/svg/badgeIOS.svg";
import ContactInfo from "@app-components/common/ContactInfo";
import FacebookSvg from "@app-components/svg/FacebookSvg";
import InstagramSvg from "@app-components/svg/InstagramSvg";
import LinkedinSvg from "@app-components/svg/LinkedinSvg";
import MTLogoWhiteSvg from "@app-components/svg/MTLogoSvg";
import { WEB_TYPE } from "@app-constants/common";
import { getRoutes } from "@app-utils/routes";

export default function LPFooter() {
  const { t } = useTranslation();

  const policies = useMemo(() => {
    const routes = getRoutes(t);
    return [
      routes.preOrder,
      routes.returnsPolicy,
      routes.faq,
      routes.privacyPolicy,
      routes.paymentPolicy,
      routes.warrantyPolicy,
      routes.terms,
      routes.shipping,
    ];
  }, [t]);

  return !WEB_TYPE ? (
    <footer className="lp-footer">
      <div className="container">
        <div className="lp-footer__top">
          <div className="logo">
            <MTLogoWhiteSvg color="black" />
            <div className="mt-title-sm">{t("contact.companyName")}</div>
          </div>
          <div className="links-container">
            <ul className="links ">
              {policies.map((route) => (
                <li key={route.title}>
                  <a href={route.path}>{route.title}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="social-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/ManyTouchesJSC/"
            >
              <FacebookSvg />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/thering.manytouches"
            >
              <InstagramSvg />
            </a>
            <a href="https://www.youtube.com/@ManyTouches">
              <YoutubeOutlined style={{ fontSize: 20, margin: "2px" }} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/many-touches-llc"
            >
              <LinkedinSvg />
            </a>
          </div>
        </div>
        <Divider />
        <div className="lp-footer__bottom">
          <div className="contact-container">
            <ContactInfo footer />
          </div>
          <div className="lp-footer__bottom-right">
            <div className="image-container">
              <Link
                to="http://online.gov.vn/Home/WebDetails/110679"
                target="_blank"
              >
                <img className="sale-noti-img" src={LogoSaleNoti} alt="" />
              </Link>
              <div className="download-links">
                <div className="app-links flex-col">
                  <div className="cursor-pointer">
                    <BadgeAndroidSvg />
                  </div>
                  <div className="cursor-pointer">
                    <BadgeIOSSvg />
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-right">
              {t("footer.copyRight")} © {t("contact.companyName")}
            </div>
          </div>
        </div>
      </div>
    </footer>
  ) : (
    <></>
  );
}
